<template>
  <div id="app">
    <div class="mask">
      <NavigationBar />

      <div class="content">
        <router-view />
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { NavigationBar, Footer },
};
</script>

<style scoped>
#app {
  background-image: url("assets/images/wreiner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.mask {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.content {
  flex-grow: 1;
  margin-top: 60px;
}

@media (max-width: 767px) {
    .content {
      flex-grow: 1;
        margin-top: 20px;
    }

    #app{
      background-size: contain; /* Make the image fit within the container */
      background-position: center; 
    }
 
}


</style>
