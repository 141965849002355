<template>
  <section class="main-content">
    <div class="container-fluid mask">
      <h1 class="text-center text-white pt-3">
        Hi {{ fanName }}'s fan 😀.
      </h1>
      <Events />

      <!-- <div class="d-flex justify-content-center align-items-center">
        <div class="card mt-5">
          <div class="card-body">
            <img
              src="../assets/images/banner.png"
              alt=""
              width="600"
              class="img-fluid"
            />
          </div>
          <div class="card-footer bg-white d-flex justify-content-center align-items-center">
            <img
              src="../assets/images/sponsors.jpg"
              alt=""
              class="img-fluid"
              width="350"
            />
          </div>
        </div>
      </div>  -->
     
    </div>
  </section>
</template>

<script>
import "@/assets/styles/main.css";
import Events from "../components/EventCards.vue";
import axios from "axios";

export default {
  computed: {
    //Fetching the stored name from the store if null it sets default value
    fanName() {
      return this.$store.state.name || "Igiza";
    },
  },
  components: { Events },
};
</script>

<style>
.debugger {
  border: 2px solid green;
}
</style>
